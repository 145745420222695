export const MIDDLEWARE = {
  AUTH: 'auth',
  PAGE_SEO: 'page-seo',
  PATH_REPLACE: 'path-replace',
  PREVENT_ROUTING: 'prevent-routing',
  ROUTER_NAVIGATION_DETAILS: 'router-navigation-details',
  SEO_REDIRECT: 'seo-redirect',
  MIGRATED_REDIRECTS: 'migrated-redirects',
  CONTENT_REFRESH_IN_BACKGROUND: 'content-refresh-in-background',
  REMOVE_LOCAL_ONE_TRUST_HANDLERS: 'remove-local-one-trust-handlers'
}
