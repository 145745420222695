import { checkIfParsedElementIsImage, parseHtml } from '@fmpedia/html-tools'
import { pathOr } from 'ramda'
import { DEFAULT_THUMBNAIL_IMAGE } from 'enums/aws-files'
import { IMAGE_SIZE, IMAGE_SIZE_VALUE } from 'enums/images'
import { processHtml } from '@/utils/helpers/processHtml'
import { rawDateUTCStringToPageSchemeFormat } from '@fmpedia/helpers'
import { MIGRATED_CATEGORY_SLUG } from 'enums/categories'
import { PAGE_SCHEME_TYPE } from 'enums/pageSchemes'
import { aspectRatios } from 'enums/aspectRatios'

export default {
  methods: {
    $_articleScheme_getThumbnailImageData(articleData) {
      const { image, thumbnailImage, bodyImages } = articleData
      const featuredImageUrl = pathOr(null, ['url'], image)
      const thumbnailImageUrl = pathOr(null, ['url'], thumbnailImage)
      const firstImageInBody = pathOr(null, [0, 'url'], bodyImages)

      const resultingImageUrl =
        featuredImageUrl ||
        thumbnailImageUrl ||
        firstImageInBody ||
        this.$helper.getLinkToFlBucketFile(DEFAULT_THUMBNAIL_IMAGE)

      const imageSize = IMAGE_SIZE.WIDTH_975

      const isFeaturedImage = featuredImageUrl === resultingImageUrl

      const imageAspectRatio =
        isFeaturedImage && !articleData.isMigrated
          ? aspectRatios.articleFeaturedImage
          : this.$_articleScheme_getThumbnailImageAspectRatioFromArticleBody({
              thumbnailUrl: resultingImageUrl,
              articleData
            })

      const imageWidth = IMAGE_SIZE_VALUE[imageSize]
      const imageHeight = +imageAspectRatio && imageWidth / imageAspectRatio

      return {
        url: this.$helper.replaceImageUrlSuffix({
          originalUrl: resultingImageUrl,
          neededSize: imageSize
        }),
        width: imageWidth,
        height: imageHeight
      }
    },
    $_articleScheme_getImageUrlWithoutSuffixAndForceQueryParam(url) {
      return this.$helper.replaceImageUrlSuffixWithOriginal(
        url.replace(/\?v=\d+$/, '')
      )
    },
    $_articleScheme_checkIfParsedImageIsThumbnailImage(img, thumbnailUrl) {
      const foundImageSrc = img.attrs['data-src']

      if (!foundImageSrc || !thumbnailUrl) return false

      return (
        this.$_articleScheme_getImageUrlWithoutSuffixAndForceQueryParam(
          foundImageSrc
        ) ===
        this.$_articleScheme_getImageUrlWithoutSuffixAndForceQueryParam(
          thumbnailUrl
        )
      )
    },
    $_articleScheme_getThumbnailImageAspectRatioFromArticleBody({
      articleData,
      thumbnailUrl
    }) {
      let aspectRatio = null

      const handlerSettings = [
        {
          conditionFn: el =>
            checkIfParsedElementIsImage(el) &&
            el.attrs &&
            this.$_articleScheme_checkIfParsedImageIsThumbnailImage(
              el,
              thumbnailUrl
            ),
          handlerFn: img => {
            const foundAspectRatio = img.attrs['aspect-ratio']
            const foundWidth = img.attrs.width
            const foundHeight = img.attrs.height

            const aspectRatioFromDimensions = foundWidth / foundHeight

            aspectRatio = foundAspectRatio || aspectRatioFromDimensions || null

            return img
          }
        }
      ]

      const parsedBody = parseHtml(articleData.body)

      this.$helper.generateParsedDomElementsHandlerDeep(handlerSettings)(
        parsedBody
      )

      return aspectRatio
    },
    $_articleScheme_generateArticleScheme({
      articleData,
      thumbnailImageData,
      canonicalUrl
    }) {
      const {
        title,
        publishedOn,
        modifiedOn,
        isSponsored,
        brief,
        body,
        category: { name: categoryName, slug: categorySlug },
        author: articleAuthor,
        company
      } = articleData

      const author = company ? company.name : articleAuthor.name

      const fullPath = this.$helper.getFullPath(this)
      const dateline = this.$helper.formatDate.toDateAndHours(publishedOn)
      const bodyText = this.$helper.htmlToText(processHtml(body))
      const articleSection = categoryName
      const wordCount = this.$helper.countWordsAmount(bodyText)

      const { Keyphrases } = this.$store.getters['seo/pageSeo']

      const publishedOnFormatted = rawDateUTCStringToPageSchemeFormat(
        publishedOn
      )
      const modifiedOnFormatted = rawDateUTCStringToPageSchemeFormat(modifiedOn)
      const {
        url: thumbnailUrl,
        width: thumbnailWidth,
        height: thumbnailHeight
      } = thumbnailImageData

      const commonFields = {
        articleBody: bodyText,
        articleSection,
        wordCount,
        alternativeHeadline: title,
        datePublished: publishedOnFormatted,
        dateModified: modifiedOnFormatted || publishedOnFormatted,
        headline: title,
        thumbnailUrl,
        thumbnailWidth,
        thumbnailHeight,
        description: brief,
        author,
        authorUrl: this.$_articleScheme_generateAuthorUrlForSchema(
          articleAuthor,
          company
        ),
        mainEntityOfPage: canonicalUrl
      }

      const isArticleScheme =
        isSponsored || categorySlug === MIGRATED_CATEGORY_SLUG.EDUCATION

      const specificSchemeFields = isArticleScheme
        ? {
            discussionUrl: fullPath,
            keywords: Keyphrases.map(({ Keyword }) => Keyword).join(', '),
            timeRequired: this.$helper.calculateTimeRequiredToRead({
              wordCount
            }),
            alternateName: title
          }
        : {
            dateline,
            genre: articleSection,
            text: fullPath
          }

      const type = isArticleScheme
        ? PAGE_SCHEME_TYPE.ARTICLE
        : PAGE_SCHEME_TYPE.NEWS_ARTICLE

      return { type, data: { ...commonFields, ...specificSchemeFields } }
    },
    $_articleScheme_generateAuthorUrlForSchema(author, company) {
      const isAuthor = !!author
      const isCompany = !!company

      if (!isAuthor && !isCompany) return ''

      if (isCompany) {
        const companyPath = this.generateCompanyPath(company)

        if (!companyPath) return ''

        return this.generateFlUrl(companyPath)
      } else {
        const authorPath = this.generateAuthorPath(author)

        if (!authorPath) return ''

        return this.generateFlUrl(authorPath)
      }
    }
  }
}
