import setPageNavigationStart from '@/utils/beforeEach/set-page-navigation-start'
import {
  handleRedirectsFactory,
  checkIfUrlContainsDomain,
  checkIfUrlStartsWithDomain,
  removeDomainFromUrl,
  removeEndingSlashes
} from '@fmpedia/helpers'
import { importRedirectSettings } from '@/utils/helpers/redirects/cache'
import { getSeoRedirectSettings } from '@/utils/helpers/redirects/seo-redirects'
import { getPageSeo } from '@/utils/helpers/seo'

let handleHardcodedRedirectsFn = null

const debug = false

function debugLog(...args) {
  if (!debug) return

  const [first, ...others] = args
  const firstArg = `beforeEAch > ${first}`

  console.log(firstArg, ...others)
}

export default function(ctx) {
  const { app, store, req } = ctx

  async function clientSideGetRedirectSettings({ fullPath }) {
    return await store.dispatch('seo/requestMigratedRedirectSettings', {
      queryParams: { fullPath }
    })
  }

  async function serverSideGetRedirectSettings({ fullPath }) {
    const getRedirectSettings = await importRedirectSettings()

    return await getRedirectSettings({
      domainUrl: process.env.DOMAIN_URL,
      fullPath
    })
  }

  async function addRouteToQueue(payload) {
    if (!process.client) return

    return store.dispatch('router/addRouteToQueue', payload)
  }

  function checkIfOtherNavigationStarted() {
    if (!process.client) return false

    return store.getters['router/redirectQueue'].length > 1
  }

  async function routeIsTheFirstInQueuePromise(fullPath) {
    if (!process.client) return

    return store.dispatch('router/routeIsTheFirstInQueuePromise', fullPath)
  }

  function getLatestRedirectTo() {
    if (!process.client) return null

    const queue = store.getters['router/redirectQueue']

    return queue[queue.length - 1]
  }

  async function removeRouteFromQueue(fullPath) {
    if (!process.client) return

    return store.dispatch('router/removeRouteFromQueue', fullPath)
  }

  function redirectToExternalUrl(url, type) {
    if (process.client) {
      window.location.href = url
    } else {
      return ctx.redirect(type, url)
    }
  }

  function getDomainUrl() {
    if (process.client) {
      return app.$env.DOMAIN_URL
    } else {
      return process.env.DOMAIN_URL
    }
  }

  function getCurrentDomainUrl() {
    if (process.client) {
      const { origin } = new URL(window.location.href)
      return origin
    } else {
      return req.headers.host
    }
  }

  app.router.beforeEach(async (to, from, next) => {
    debugLog('== beforeEach() ==')
    if (process.client && from.fullPath === '/' && !from.name) {
      debugLog('client-side, initial load, do nothing')
      debugLog('from: ', from)
      debugLog('to: ', to)
      next()
      return
    }

    debugLog('currentDomainUrl: ', getCurrentDomainUrl())

    setPageNavigationStart({ store })

    debugLog('to.fullPath: ', to.fullPath)
    debugLog('from.fullPath: ', from.fullPath)

    let latestRedirectTo = { fullPath: to.fullPath }

    debugLog('before addRouteToQueue')
    debugLog('from.path: ', from.path)
    debugLog('to.path: ', to.path)
    /**
     * If the path remains unchanged (only query parameters or hash are modified),
     * the page's asyncData will not trigger, and the page will not be removed from the queue.
     * To prevent the queue from freezing, such routes are excluded from the queue workflow
     * since the page itself has not changed.
     */
    if (removeEndingSlashes(to.path) === removeEndingSlashes(from.path)) {
      debugLog('the path has not been changed. Use next()')
      next()
      return
    }

    debugLog('the path has been changed')
    await addRouteToQueue(latestRedirectTo)
    const currentRedirect = to

    debugLog('before routeIsTheFirstInQueuePromise')
    const promiseToAwait = routeIsTheFirstInQueuePromise(to.fullPath)

    debugLog('before checkIfOtherNavigationStarted')
    if (checkIfOtherNavigationStarted()) {
      debugLog('Found another navigation. Await')
      debugLog('promiseToAwait: ', promiseToAwait)
      await promiseToAwait
      debugLog('After promiseToAwait: ', currentRedirect.fullPath)
    }

    latestRedirectTo = getLatestRedirectTo() || { fullPath: to.fullPath }
    debugLog('latestRedirectTo: ', latestRedirectTo)

    if (latestRedirectTo.fullPath !== currentRedirect.fullPath) {
      debugLog('Current navigation is not the latest')
      debugLog('Cancel this beforeEach and remove the route from the queue')
      await removeRouteFromQueue(currentRedirect.fullPath)
      return
    }

    if (!handleHardcodedRedirectsFn) {
      debugLog('== Create handleHardcodedRedirectsFn() ==')
      handleHardcodedRedirectsFn = handleRedirectsFactory({
        clientSideGetRedirectSettings,
        serverSideGetRedirectSettings,
        debug: false
      })
    }

    debugLog('handleHardcodedRedirectsFn: ', currentRedirect.fullPath)
    const hardcodedRedirectSettingsPromise = handleHardcodedRedirectsFn(
      to.fullPath
    )

    const seoRedirectSettingsPromise = getSeoRedirectSettings({
      route: to,
      ctx
    })

    const getPageSeoPromise = getPageSeo({
      route: to,
      ctx
    })

    const [hardcodedRedirectSettings, seoRedirectSettings] = await Promise.all([
      hardcodedRedirectSettingsPromise,
      seoRedirectSettingsPromise,
      getPageSeoPromise
    ])

    const redirectSettings = seoRedirectSettings || hardcodedRedirectSettings
    debugLog(
      `redirectSetting (${currentRedirect.fullPath}): `,
      redirectSettings
    )
    debugLog('Now check if the current navigation is the latest')

    latestRedirectTo = getLatestRedirectTo() || { fullPath: to.fullPath }
    debugLog('latestRedirectTo: ', latestRedirectTo.fullPath)

    const isCurrentRouteLatest = to.fullPath === latestRedirectTo.fullPath
    debugLog('isCurrentRouteLatest: ', isCurrentRouteLatest)

    if (isCurrentRouteLatest && !redirectSettings) {
      debugLog("Navigation wasn't changed. Load the page")
      debugLog('to.name: ', to.name)
      debugLog('to.fullPath: ', to.fullPath)
      debugLog('latestRedirectTo.fullPath: ', latestRedirectTo.fullPath)
      next()
      return
    }

    if (!isCurrentRouteLatest) {
      debugLog('Navigation was changed (to !== latestRedirectTo)')
      debugLog('This beforeEach() should be cancelled! Return')
      debugLog('to.fullPath: ', to.fullPath)
      debugLog('latestRedirectTo.fullPath: ', latestRedirectTo.fullPath)
      debugLog('Remove route from the queue')
      await removeRouteFromQueue(currentRedirect.fullPath)
      return
    }

    debugLog('Redirect settings found!!!')
    debugLog('to: ', redirectSettings.to)
    debugLog('type: ', redirectSettings.type)

    const domainUrl = getDomainUrl()
    debugLog('domainUrl: ', domainUrl)

    const toWithoutDomain =
      removeDomainFromUrl(redirectSettings.to, domainUrl) || '/'
    const isExternal =
      checkIfUrlContainsDomain(redirectSettings.to) &&
      !checkIfUrlStartsWithDomain(redirectSettings.to, domainUrl)
    debugLog('toWithoutDomain: ', toWithoutDomain)
    debugLog('isExternal: ', isExternal)

    if (isExternal) {
      return redirectToExternalUrl(redirectSettings.to, redirectSettings.type)
    } else {
      debugLog('REDIRECT!!!')
      debugLog('next(toWithoutDomain)')
      if (process.client) {
        next(toWithoutDomain)
      } else {
        ctx.redirect(redirectSettings.type, redirectSettings.to)
      }
    }
  })
}
