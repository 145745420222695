import { mapGetters } from 'vuex'
import _throttle from 'lodash.throttle'
import { pathOr, prop } from 'ramda'

import errorMessages from '@/utils/validators/error-messages'
import { getPasswordValidationMessage } from '@fmpedia/validators'
import { ROUTE_NAME } from 'enums/routes'
import { DEFAULT_FALLBACK_LINK } from 'enums/links'
import { forceUrlFetch, isFile, processResponse } from '@/plugins/helper'
import {
  generateArticlePath,
  generateAuthorPath,
  generateCategoryPath,
  generateCompanyPath,
  generateFlUrl,
  generateFmDirUrl,
  generateFmUrl,
  generateTagPath,
  generateTermUrl
} from '@/utils/helpers/url-generators'
import { THROTTLE_DEFAULT_DURATION } from 'enums/settings'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { HEADER_SCROLL_OFFSET } from 'enums/header-scroll-offset'

export const windowWidth = {
  mixins: [hydrationHelpers],
  data() {
    return {
      throttledWindowSizeCheck: null,
      throttledOnScrollHandler: null
    }
  },
  computed: {
    ...mapGetters({
      isAppleBrowser: 'isAppleBrowser',
      previousScrollTop: 'previousScrollTop'
    }),
    currentMobileBottomPadding() {
      if (!this.$_hydrationHelpers_isLayoutMobileOrTablet) {
        return 0
      }

      const iosBottomPadding = 75
      const androidBottomPadding = 56
      return this.isAppleBrowser ? iosBottomPadding : androidBottomPadding
    }
  },
  methods: {
    checkWindowWidthAndHeight() {
      if (process.browser) {
        const windowWidth = this.$helper.getWindowWidth()
        const windowHeight = this.$helper.getWindowHeight()
        this.$store.dispatch('setWindowWidth', windowWidth)
        this.$store.dispatch('setWindowHeight', windowHeight)
        this.updateMobileBottomPadding()
      }
    },
    onScrollHandler() {
      const scrollTop = this.$helper.getWindowScrollTop()
      this.$store.dispatch('setScrollTop', scrollTop)

      sessionStorage.setItem('savedPosition', scrollTop)
    },
    updateMobileBottomPadding() {
      this.$store.dispatch(
        'setMobileBottomPadding',
        this.currentMobileBottomPadding
      )
    },
    handleFullScreenElements() {
      const fullscreenElement = [
        document.fullscreenElement,
        document.fullscreen,
        document.webkitIsFullScreen,
        document.webkitFullscreenElement,
        document.msFullscreenElement
      ].filter(v => v !== undefined)[0]

      if (!fullscreenElement) {
        /**
         * previousScrollTop value is used to restore scroll position after
         * exiting from YouTube fullscreen mode (or any other fullscreen DOM element)
         * Details: Youtube scrolls the page to top when entering fullscreen mode.
         * We handle "fullscreenchange" event, but when we get scroll position after
         * fullscreen is emitted, scroll position is already 0.
         * That's why we save previous value to restore scroll position after exiting
         * fullscreen mode (it is handled by windowWidth mixin).
         */
        window.scrollTo(0, this.previousScrollTop)
      }
    }
  },
  mounted() {
    this.throttledWindowSizeCheck = _throttle(
      this.checkWindowWidthAndHeight,
      THROTTLE_DEFAULT_DURATION
    )
    this.throttledOnScrollHandler = _throttle(
      this.onScrollHandler,
      THROTTLE_DEFAULT_DURATION
    )

    this.throttledWindowSizeCheck()
    this.throttledOnScrollHandler()

    window.addEventListener('resize', this.throttledWindowSizeCheck, true)
    window.addEventListener(
      'scroll',
      () => {
        this.throttledOnScrollHandler()
      },
      true
    )
    window.addEventListener('fullscreenchange', this.handleFullScreenElements)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttledWindowSizeCheck, true)
    window.removeEventListener('scroll', this.throttledOnScrollHandler, true)
    window.removeEventListener(
      'fullscreenchange',
      this.handleFullScreenElements
    )
  }
}

export const urlGenerators = {
  methods: {
    generateFlUrl(link) {
      return generateFlUrl(link, this)
    },
    generateFmUrl(link) {
      return generateFmUrl(link, this)
    },
    generateFmDirUrl(link) {
      return generateFmDirUrl(link, this)
    },
    generateTermRoute(params = {}) {
      const formattedParams = processResponse(params)
      const { slug: term } = formattedParams

      if (!term) return DEFAULT_FALLBACK_LINK

      return {
        name: ROUTE_NAME.TERMS_LETTER_TERM,
        params: { letter: term.charAt(0), term }
      }
    },
    generateTermUrl(params = {}) {
      return generateTermUrl(params, this)
    },
    generateArticlePath,
    generateFmDirArticleUrl(article) {
      if (article.Url) {
        return article.Url
      }

      if (article.FmDirArticleUrl) {
        return this.generateFmUrl(article.FmArticleUrl)
      }

      if (!article.CategorySlug) {
        console.log(
          '%cCategorySlug not found from backend!',
          'color: black ; background-color: cyan'
        )
      }

      const { CategorySlug, CompanySlug } = article

      // TODO - remove "Slug" after changes on backend - globalsearch route
      const articleSlug = article.ArticleSlug || article.Slug
      const link = `/${CategorySlug}/${CompanySlug}/${articleSlug}`

      return this.generateFmDirUrl(link)
    },
    generateFmArticleUrl(article) {
      if (article.Url) {
        return article.Url
      }

      if (!prop('CategorySlug', article)) {
        console.log(
          '%cCategorySlug not found from backend!',
          'color: black ; background-color: cyan'
        )
      }

      const categorySlug = pathOr('', ['CategorySlug'], article)

      // TODO - remove "SubcategorySlug" after changes on backend - globalsearch route
      const subcategorySlug =
        pathOr('', ['SubCategorySlug'], article) ||
        pathOr('', ['SubcategorySlug'], article)

      // TODO - remove "Slug" after changes on backend - globalsearch route
      const articleSlug =
        prop('ArticleSlug', article) ||
        prop('Slug', article) ||
        this.$route.params.article

      const link = `/${categorySlug}/${
        subcategorySlug ? subcategorySlug + '/' : ''
      }${articleSlug}`
      return this.generateFmUrl(link)
    },
    generateTagPath,
    generateCompanyPath,
    generateAuthorPath,
    generateCategoryPath,
    generateEducationHubUrl() {
      return 'https://fl-education.com/?utm_source=forexlivesite&utm_medium=button&utm_campaign=Forexlivesitebutton'
    },
    generatePremiumUrl() {
      return 'https://insights.forexlive.com/premium/?utm_source=FLSITE&utm_medium=button&utm_campaign=Premiumflsitebutton'
    },
    generateStockRoute(symbol) {
      if (!symbol) return null

      return {
        name: ROUTE_NAME.STOCKS_STOCK,
        params: {
          stock: `${symbol.toUpperCase()}-stock`
        }
      }
    }
  }
}
/* eslint-disable */

const YOUTUBE_ID_LENGTH = 11

function getYoutubeVideoId(url) {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const id = (match && match[2]) || ''

  return id.length === YOUTUBE_ID_LENGTH ? id : null
}

function isYoutubeUrl(url) {
  const regExp = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
  return regExp.test(url)
}

export const urlFormatters = {
  methods: {
    isYoutubeUrl,
    getYoutubeVideoId,
    convertYoutubeUrlToEmbedUrl(url) {
      if (this.isYoutubeUrl(url)) {
        const id = getYoutubeVideoId(url)
        return this.$helper.formatYoutubeIdToEmbedUrl(id)
      }
      return url
    },
    /**
     * This method is used to refresh images with the same url (i.e. user Photo)
     * It can work inside computed property together with getter of PhotoUrl
     * @param url
     * @returns {*|string}
     */
    getActualPhotoUrl(url) {
      return url && !isFile(url) ? forceUrlFetch(url) : url
    }
  }
}

function getMessage({ errorType }) {
  /**
   * searchResult contains array of results.
   * From developer.mozilla.org:
   * If the string matches the expression, it will return an Array containing the entire
   * matched string as the first element, followed by any results captured in parentheses.
   * If there were no matches, null is returned.
   */
  const searchResult = errorType.match(this.regExp)
  return searchResult ? this.message.replace('${}', searchResult[1]) : ''
}

function getPasswordMessage({ errorType, error }) {
  if (errorType === 'password') {
    const password = pathOr('', ['$params', errorType, 'password'], error)
    return getPasswordValidationMessage(password)
  }
  return ''
}

/* eslint-enable */
export const validation = {
  data() {
    return {
      dynamicValidationTypes: [
        {
          regExp: /minLength\(([^)]+)\)/,
          message: 'This field should not be less than ${} characters',
          getMessage
        },
        {
          regExp: /maxLength\(([^)]+)\)/,
          message: 'This field should not exceed ${} characters',
          getMessage
        },
        {
          regExp: /minCount\(([^)]+)\)/,
          message: 'This field should contain at least ${} elements',
          getMessage
        },
        {
          regExp: /maxCount\(([^)]+)\)/,
          message: 'This field should not contain more than ${} elements',
          getMessage
        },
        {
          getMessage: getPasswordMessage
        }
      ]
    }
  },
  computed: {},
  methods: {
    $_validation_hasValidationError(error = this.error) {
      return error && error.$error
    },
    $_validation_errorMessages(error = this.error) {
      if (error && !this.$_validation_hasValidationError(error)) {
        return []
      }
      const errorParams = []
      /* eslint-disable */
      if (error.$params !== undefined && Object.keys(error.$params).length) {
        for (const param in error.$params) {
          if (error.$params.hasOwnProperty(param) && error[param] === false) {
            errorParams.push(param)
          }
        }
      }
      /* eslint-enable */
      return this.$_validation_generateErrors({ errorParams, error })
    },
    $_validation_generateErrors({ errorParams = [], error }) {
      const errors = errorMessages
      const staticErrorMessages = errors
        .filter(m => errorParams.indexOf(m.type) > -1)
        .map(e => e.message)
      let dynamicErrorMessages = []
      errorParams.forEach(errorType => {
        const result = this.$_validation_generateDynamicErrorMessage({
          errorType,
          error
        })
        if (result.length) {
          dynamicErrorMessages = [...dynamicErrorMessages, ...result]
        }
      })
      return [...staticErrorMessages, ...dynamicErrorMessages]
    },
    $_validation_generateDynamicErrorMessage({ errorType, error }) {
      return this.dynamicValidationTypes
        .map(dynamicError => dynamicError.getMessage({ errorType, error }))
        .filter(searchResult => searchResult !== '')
    }
  }
}

export const setPageLoaded = {
  methods: {
    $_setPageLoaded_setStatus(status) {
      this.$store.dispatch('setPageLoaded', status)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$_setPageLoaded_setStatus(true)
    })
  }
}

export const initialScroll = {
  mounted() {
    const selector = this.$route.hash || this.$route.params.scrollTo
    if (selector) {
      const offset = this.$route.params.offset || HEADER_SCROLL_OFFSET
      setTimeout(() => {
        this.$scrollTo(selector, 1000, {
          offset
        })
      }, 1000)
    }
  }
}
