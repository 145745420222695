import { guid } from '../../../server/helper'

// These enums must be synced with relevant SCSS variables
export const ADBLOCKER_ELEMENT_PLACEHOLDER = {
  BANNER_SPACE_ALLOCATOR: 'guid-858152b3',
  MOBILE_MODIFIER: 'guid-54f4fad7',
  TABLET_MODIFIER: 'guid-2db57319',
  DESKTOP_SM_MODIFIER: 'guid-22185714',
  DESKTOP_SM2_MODIFIER: 'guid-0811188a',
  DESKTOP_SM3_MODIFIER: 'guid-6c30c1c4',
  DESKTOP_MD_MODIFIER: 'guid-00f773b4',
  DESKTOP_LG_MODIFIER: 'guid-4225ed7f',
  DESKTOP_XL_MODIFIER: 'guid-48b20689',
  ABOVE_DESKTOP_XL_MODIFIER: 'guid-2bd39431',
  SPONSORED_ARTICLE_INDICATOR: 'guid-1026f3cc'
}

const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE = {
  [ADBLOCKER_ELEMENT_PLACEHOLDER.BANNER_SPACE_ALLOCATOR]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.MOBILE_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.TABLET_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM2_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM3_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_MD_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_LG_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.SPONSORED_ARTICLE_INDICATOR]: null
}

function generateUniqueId(existingIds = []) {
  let id
  do {
    id = `guid-${guid().match(/[\d\w]*/)[0]}`
  } while (existingIds.includes(id))

  return id
}

function fillDynamicValues(obj) {
  const usedIds = []
  const resultObject = {}

  for (const key in obj) {
    const uniqueId = generateUniqueId(usedIds)
    resultObject[key] = uniqueId
    usedIds.push(uniqueId)
  }

  return resultObject
}

export const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_BY_PLACEHOLDER = fillDynamicValues(
  ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE
)
