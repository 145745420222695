<template>
  <div class="global-wrapper" :class="globalWrapDynamicClass">
    <a-banner
      :banner-settings="bannerSettings.TOP_SIDE"
      :custom-breakpoint-handler="wideSkyBreakpointHandler"
      class="above-header-meta-content"
    />
    <a-sticky
      :id="$options.consts.TOP_STICKY_CONTAINER_ID"
      class="top-sticky-container"
    >
      <a-header
        :last-modified="headerMenuItemsLastModified"
        :is-desktop-header-mounted.sync="isDesktopHeaderMounted"
        :is-mobile-header-mounted.sync="isMobileHeaderMounted"
      />
    </a-sticky>
    <div class="page-wrap" :class="dynamicPageWrapClass">
      <div
        class="content-wrapper"
        :data-ref="$options.consts.REFS.CONTENT_WRAPPER"
      >
        <nuxt ref="nuxt" />
      </div>
      <a-visibility hide :to="$breakpoint.desktopSm2">
        <a-layout-widgets :style="contentTopPadding" class="layout-widgets" />
      </a-visibility>
      <a-visibility hide :on="breakpointsToHideSidebarOn">
        <div class="sidebar">
          <a-sidebar />
        </div>
      </a-visibility>
      <a-visibility hide :to="$breakpoint.desktopSm2">
        <div class="banners-section">
          <a-layout-banners-section />
        </div>
      </a-visibility>
    </div>

    <a-news-letter-modal />

    <a-footer :last-modified="footerLastModified" />

    <!--lazy-->
    <a-auth-form />

    <a-cross-domain-cookies />

    <a-device-id-cookie-iframe />

    <a-google-analytics-cookies-iframe />

    <client-only>
      <a-error-handler-notifier v-if="isDevelopment" />
    </client-only>

    <a-char-width />

    <gtm-no-script />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapActions, mapGetters } from 'vuex'
import { pathOr } from 'ramda'

import { COOKIE } from 'enums/cookies'
import mixins from '@/utils/mixins'
import AHeader from '@/components/AHeader'
import AAuthForm from 'shared/AAuthForm/lazy'
import ACrossDomainCookies from '@/components/_layout/ACrossDomainCookies'
import ADeviceIdCookieIframe from '@/components/_layout/ADeviceIdCookieIframe'
import AGoogleAnalyticsCookiesIframe from '@/components/_layout/AGoogleAnalyticsCookiesIframe'
import ABanner from 'shared/ABanner'
import ANewsLetterModal from 'shared/ANewsLetterModal'
import {
  ALL_BREAKPOINTS,
  BREAKPOINTS_TO_HIDE_SIDEBAR_ON_BY_PAGE,
  DEFAULT_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
  PAGES_WITHOUT_SIDEBAR,
  WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON
} from 'enums/sidebar'
import { BANNER_SETTINGS_BY_ROUTE_NAME } from 'enums/banners/banner-settings-by-route-name'
import {
  WIDE_SKY_BANNERS_BREAKPOINT_NAME,
  WIDE_SKY_BANNERS_BREAKPOINT_VALUE
} from 'enums/banners/banner-settings'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import GtmNoScript from 'shared/GtmNoScript'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import ACharWidth from 'shared/ACharWidth'
import { QUERY_PARAM } from 'enums/query-param'
import ALayoutBannersSection from '@/components/_layout/ALayoutBannersSection'
import { LAYOUT_STICKY_TOP_PADDING } from 'enums/settings'
import { TOP_STICKY_CONTAINER_ID } from 'enums/header'
import { REFS } from 'enums/external-refs'
import ASticky from 'shared/ASticky'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { removeDomainFromUrl } from '@fmpedia/helpers'

const PAGE_WRAP_CLASS_MODIFIER = {
  NO_SIDEBAR: 'content-widget-banner',
  SIDEBAR1: 'content-widget-sidebar1-banner', // sidebar hides on desktopSm2
  SIDEBAR2: 'content-widget-sidebar2-banner' // sidebar hides on desktopSm
}

export default {
  name: 'DefaultLayout',
  components: {
    ACharWidth,
    GtmNoScript,
    ANewsLetterModal,
    ABanner,
    ALayoutWidgets: hydrateWhenVisible(() =>
      import('@/components/_layout/ALayoutWidgets')
    ),
    AHeader,
    AFooter: hydrateWhenVisible(() => import('@/components/AFooter'), {
      observerOptions: { rootMargin: '100px' },
      props: ['last-modified']
    }),
    AAuthForm,
    ACrossDomainCookies,
    AErrorHandlerNotifier: () => import('shared/AErrorHandlerNotifier'),
    ASidebar: hydrateWhenVisible(() => import('@/components/_layout/ASidebar')),
    AGoogleAnalyticsCookiesIframe,
    ADeviceIdCookieIframe,
    ALayoutBannersSection,
    ASticky
  },
  mixins: [
    mixins.windowWidth,
    mixins.googleBanners,
    mixins.setPageLoaded,
    mixins.userDetails,
    mixins.oneSignal,
    mixins.signalr,
    mixins.documentVisibility,
    mixins.internetConnectionState,
    mixins.oneTrust,
    mixins.oneTrustListener,
    mixins.actionsOnQueryParams
  ],
  head() {
    const isCookiePopupHidden = !!this.$route.query[
      QUERY_PARAM.HIDE_COOKIE_POPUP
    ]

    const oneTrustScript = {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      type: 'text/javascript',
      'data-domain-script': this.$env.ONE_TRUST_SCRIPT_ID,
      defer: true
    }

    const signalRScript = {
      src: '/signalr.min.js',
      type: 'text/javascript',
      defer: true
    }

    const hideCookiePopupStyle = {
      type: 'text/css',
      innerHTML: '#onetrust-consent-sdk { display: none; }'
    }

    return {
      style: [...(isCookiePopupHidden ? [hideCookiePopupStyle] : [])],
      script: [...(this.isPreviewMode ? [] : [oneTrustScript]), signalRScript]
    }
  },
  consts: {
    TOP_STICKY_CONTAINER_ID,
    REFS
  },
  data() {
    return {
      layoutTopPadding: LAYOUT_STICKY_TOP_PADDING,
      isDesktopHeaderMounted: false,
      isMobileHeaderMounted: false
    }
  },
  computed: {
    ...mapGetters({
      isPageLoaded: 'isPageLoaded',
      headerMenuItemsLastModified: 'headerMenuItemsLastModified',
      footerMenuItemsLastModified: 'footerMenuItemsLastModified',
      stayConnectedLastModified: 'stayConnectedLastModified',
      isPreviewMode: 'isPreviewMode',
      redirectQueue: 'router/redirectQueue'
    }),
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    breakpointsToHideSidebarOn() {
      return this.isPageWithSidebar
        ? BREAKPOINTS_TO_HIDE_SIDEBAR_ON_BY_PAGE[this.$route.name] ||
            DEFAULT_BREAKPOINTS_TO_HIDE_SIDEBAR_ON
        : ALL_BREAKPOINTS
    },
    contentTopPadding() {
      return {
        paddingTop: `${this.layoutTopPadding}px`
      }
    },
    bannerSettings() {
      return BANNER_SETTINGS_BY_ROUTE_NAME[this.$route.name] || {}
    },
    dynamicPageWrapClass() {
      if (!this.isPageWithSidebar) {
        return [PAGE_WRAP_CLASS_MODIFIER.NO_SIDEBAR]
      }

      const isLayoutWithSidebar1 = this.$helper.compareArrays(
        DEFAULT_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
        this.breakpointsToHideSidebarOn
      )
      if (isLayoutWithSidebar1) {
        return [PAGE_WRAP_CLASS_MODIFIER.SIDEBAR1]
      }

      const isLayoutWithSidebar2 = this.$helper.compareArrays(
        WIDGET_PAGES_BREAKPOINTS_TO_HIDE_SIDEBAR_ON,
        this.breakpointsToHideSidebarOn
      )
      if (isLayoutWithSidebar2) {
        return [PAGE_WRAP_CLASS_MODIFIER.SIDEBAR2]
      }

      /** fallback default **/
      return [PAGE_WRAP_CLASS_MODIFIER.SIDEBAR1]
    },
    isPageWithSidebar() {
      return !PAGES_WITHOUT_SIDEBAR.includes(this.$route.name)
    },
    footerLastModified() {
      return this.footerMenuItemsLastModified + this.stayConnectedLastModified
    },
    globalWrapDynamicClass() {
      return {
        'preview-mode': this.isPreviewMode
      }
    }
  },
  watch: {
    isPageLoaded: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.layoutTopPadding = pathOr(
              LAYOUT_STICKY_TOP_PADDING,
              ['nuxt', '$children', '0', 'pageSettings', 'layoutTopPadding'],
              this.$refs
            )
          })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      requestAuthStatus: 'auth/requestAuthStatus',
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent',
      removeRouteFromQueue: 'router/removeRouteFromQueue'
    }),
    registerBeforeUnloadListener() {
      /** Such an approach technique as to define - is page loaded in newly opened tab, or as result of tab reloading.
       * What do we do:
       * On page reload - we set a cookie "PAGE_RELOADED".
       * By checking this cookie - we can define, is page reloaded in current tab, or page is opened in new tab.
       * (cookie is accessible in serverMiddleware, nuxtServerInit, asyncData, etc).
       * "PAGE_RELOADED" cookie is sent to the server on page reload, and then expired and removed from browser in 3 seconds. **/

      window.addEventListener('beforeunload', function() {
        const date = new Date()
        const expiringOffset = 3 * 1000
        date.setTime(date.getTime() + expiringOffset)
        Cookies.set(COOKIE.PAGE_RELOADED, COOKIE.PAGE_RELOADED, {
          expires: date
        })
      })
    },
    wideSkyBreakpointHandler(width) {
      if (width >= WIDE_SKY_BANNERS_BREAKPOINT_VALUE) {
        return WIDE_SKY_BANNERS_BREAKPOINT_NAME
      }

      return null
    },
    handleRouterQueueCollisions() {
      const currentUrl = window.location.href
      const currentPath = removeDomainFromUrl(currentUrl, this.$env.DOMAIN_URL)
      const latestRedirect = this.redirectQueue[this.redirectQueue.length - 1]

      if (!latestRedirect) return

      const latestRedirectPath = latestRedirect.fullPath
      /**
       * Back/Forward button clicked but beforeEach wasn't called due to duplicate
       * route in navigation (cached)
       */
      if (currentPath !== latestRedirectPath) {
        this.redirectQueue
          .filter(({ fullPath }) => fullPath !== currentPath)
          .forEach(({ fullPath }) => {
            this.removeRouteFromQueue(fullPath)
          })
      }
    }
  },
  mounted() {
    this.requestAuthStatus()
    addEventListener('popstate', this.handleRouterQueueCollisions)

    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: this.registerBeforeUnloadListener,
        entityType: COOKIE_ENTITY_TYPE.PAGE_RELOADED_COOKIE
      })
    )
  },
  beforeDestroy() {
    removeEventListener('popstate', this.handleRouterQueueCollisions)
  }
}
</script>

<style lang="scss" scoped>
.global-wrapper {
  .page-wrap {
    /deep/ .layout-widgets {
      width: 60px;

      @include desktop-sm {
        display: none;
      }
    }

    .banner-wrapper {
      @include desktop-sm2 {
        display: none;
      }
    }
  }
}

.sidebar {
  display: block;
  width: $sidebar-width-lg;
  min-height: 500px;
  flex-shrink: 0;

  @include sidebar-small-size {
    width: $sidebar-width-sm;
  }
}
</style>
