import Vue from 'vue'
import {
  FL_META_TITLE_PARAMS_REGEX,
  FL_META_DESCRIPTION_PARAMS_REGEX,
  META_PARAM
} from 'enums/fl-seo'
import {
  FL_DIR_META_TITLE_PARAMS_REGEX,
  FL_DIR_META_DESCRIPTION_PARAMS_REGEX
} from 'enums/fl-dir-seo'
import { NO_DIRECTIVE } from '@fmpedia/enums'
import { generatePageScheme } from '@/utils/pageScheme'
import { SOURCE } from 'enums/source'
import { getLinkToFlBucketFile } from '@/plugins/helper'

const DEFAULT_SEO_SOURCE = 'seo/pageSeo'

const META_PARAMS_REGEX_BY_PAGE_SOURCE = {
  [SOURCE.FL]: {
    metaTitle: FL_META_TITLE_PARAMS_REGEX,
    metaDescription: FL_META_DESCRIPTION_PARAMS_REGEX
  },
  [SOURCE.FL_DIR]: {
    metaTitle: FL_DIR_META_TITLE_PARAMS_REGEX,
    metaDescription: FL_DIR_META_DESCRIPTION_PARAMS_REGEX
  }
}

const generateMetaTags = function({
  titleParam = null,
  descriptionParam = null,
  schemes = null,
  additionalLinks = [],
  uncrawlable = false,
  source = DEFAULT_SEO_SOURCE,
  script = [],
  disableSanitizersByTagIdOptions = {},
  canonicalUrl,
  ogImageUrl = getLinkToFlBucketFile('fl-thumbnail.jpg'),
  additionalRobotDirectives
}) {
  const link = [...additionalLinks]
  const meta = []

  // TODO - DO NOT REMOVE FOR NOW
  // const url = this.$env.DOMAIN_URL + this.$route.path
  // const metaTags = [
  //   { hid: 'description', name: 'description', content: description },
  //   { hid: 'keywords', name: 'keywords', content: null },
  //   {
  //     hid: 'apple-mobile-web-app-title',
  //     name: 'apple-mobile-web-app-title',
  //     content: title
  //   },
  //   { hid: 'og-title', property: 'og:title', content: title },
  //   { hid: 'og-description', property: 'og:description', content: description },
  //   { hid: 'og-type', property: 'og:type', content: 'website' },
  //   { hid: 'og-url', property: 'og:url', content: url },
  //   { hid: 'og-image', property: 'og:image', content: socialInfo.sharingImage },
  //   { hid: 'og-locale', property: 'og:locale', content: 'en_US' },
  //   {
  //     hid: 'og-site-name',
  //     property: 'og:site_name',
  //     content: 'FMPedia - financial news portal'
  //   }
  // ]

  /** Client requirement - to not add canonical links for paginated pages 8+
   *  (means, for main page and 7 first page - we add canonical links,
   *  but for 8+ pages - don't add) **/
  const MAX_PAGE_NUMBER_WITH_ALLOWED_CANONICAL = 7
  const path = this.$route.path.toLowerCase()

  const paginationMatch = path.match(/\/page\/([0-9]*)\/*$/)
  const isPageWithoutPagination = !paginationMatch

  const isCanonicalLinkAllowed =
    isPageWithoutPagination ||
    parseInt(paginationMatch[1]) <= MAX_PAGE_NUMBER_WITH_ALLOWED_CANONICAL

  if (isCanonicalLinkAllowed && canonicalUrl) {
    link.push({
      hid: 'canonical',
      rel: 'canonical',
      href: canonicalUrl
    })
  }

  const pageSource = this.$helper.getPageSourceByRouteName(this.$route.name)

  /**
   *  Generate metatags **/

  const {
    MetaTitle,
    MetaDescription,
    Directives,
    Keyphrases
  } = this.$store.getters[source]

  const title = generateTitleByPageSource(MetaTitle, titleParam, pageSource)
  const description = generateDescriptionByPageSource(
    MetaDescription,
    descriptionParam,
    pageSource
  )
  const ogSiteName = this.$store.getters['seo/ogSiteName']
  const keywords = generateKeywords(Keyphrases)
  const isPreviewUrl = !!this.$route.query.previewId
  const robotDirectives = generateDirectives(
    Directives,
    additionalRobotDirectives,
    isPreviewUrl || uncrawlable || !isCanonicalLinkAllowed
  )

  /** Generate article scheme **/
  const pageScheme = generatePageScheme(schemes)

  meta.push(
    ...getMetaSettings(
      canonicalUrl,
      title,
      description,
      keywords,
      robotDirectives,
      ogSiteName,
      ogImageUrl
    )
  )

  return {
    title,
    link,
    meta,
    __dangerouslyDisableSanitizersByTagID: {
      'page-schemas': ['innerHTML'],
      ...disableSanitizersByTagIdOptions
    },
    script: [...pageScheme.script, ...script]
  }
}

export function generateHomepageTitle(title) {
  return generateTitleByPageSource(title, META_PARAM.ALL_NEWS, SOURCE.FL)
}

function generateTitleByPageSource(template, param, pageSource) {
  return (
    (template || '').replace(
      META_PARAMS_REGEX_BY_PAGE_SOURCE[pageSource].metaTitle,
      param
    ) || param
  )
}

function generateDescriptionByPageSource(template, param, pageSource) {
  return (
    (template || '').replace(
      META_PARAMS_REGEX_BY_PAGE_SOURCE[pageSource].metaDescription,
      param
    ) || param
  )
}

function generateKeywords(keyphrases) {
  if (!keyphrases || !Array.isArray(keyphrases)) return ''

  return keyphrases.map(({ Keyword }) => Keyword).join(', ')
}

function directivesStrToArr(directivesStr) {
  if (!directivesStr) return []

  return directivesStr.split(',').map(v => v.trim())
}

function generateDirectives(directives, additionalRobotDirectives, isNoindex) {
  let calculatedDirectives

  if (isNoindex) {
    calculatedDirectives = 'noindex'
  } else {
    calculatedDirectives =
      directives === NO_DIRECTIVE ? '' : directives.toLowerCase()
  }

  return [
    ...new Set(
      [
        ...directivesStrToArr(calculatedDirectives),
        ...directivesStrToArr(additionalRobotDirectives)
      ].filter(v => !!v)
    )
  ].join(', ')
}

function generateNoindexNofollowMetaTags({
  titleParam = null,
  descriptionParam = null,
  source = null,
  staticTitle = null,
  staticDescription = null,
  ogImageUrl = getLinkToFlBucketFile('fl-thumbnail.jpg')
} = {}) {
  const { MetaTitle, MetaDescription } = this.$store.getters[
    source || DEFAULT_SEO_SOURCE
  ]

  const pageSource = this.$helper.getPageSourceByRouteName(this.$route.name)

  const meta = [
    {
      hid: 'directives',
      name: 'robots',
      content: 'noindex, nofollow'
    },
    ...generateThumbnailMetaSettings(ogImageUrl)
  ]

  let title

  if (titleParam || staticTitle) {
    title =
      staticTitle ||
      generateTitleByPageSource(MetaTitle, titleParam, pageSource)
  }

  if (descriptionParam || staticDescription) {
    const descriptionMeta = {
      hid: 'description',
      name: 'description',
      content:
        staticDescription ||
        generateDescriptionByPageSource(
          MetaDescription,
          descriptionParam,
          pageSource
        )
    }

    meta.push(descriptionMeta)
  }

  return {
    ...(title ? { title } : {}),
    meta
  }
}

const createMeta = {
  install(Vue) {
    Vue.prototype.$generateMetaTags = generateMetaTags
    Vue.prototype.$generateNoindexNofollowMetaTags = generateNoindexNofollowMetaTags
  }
}

function generateThumbnailMetaSettings(ogImageUrl) {
  if (!ogImageUrl) return []

  return [
    { hid: 'og-image', property: 'og:image', content: ogImageUrl },
    {
      hid: 'og:image:secure_url',
      property: 'og:image',
      content: ogImageUrl
    },
    {
      hid: 'twitter-image',
      name: 'twitter:image',
      content: ogImageUrl
    }
  ]
}

function getMetaSettings(
  fullPath,
  title,
  description,
  keywords,
  robotDirectives,
  ogSiteName,
  ogImageUrl
) {
  return [
    { hid: 'description', name: 'description', content: description },
    ...(keywords
      ? [{ hid: 'keywords', name: 'keywords', content: keywords }]
      : []),
    { hid: 'directives', name: 'robots', content: robotDirectives },
    {
      hid: 'apple-mobile-web-app-title',
      name: 'apple-mobile-web-app-title',
      content: title
    },
    { hid: 'og-title', property: 'og:title', content: title },
    {
      hid: 'og-description',
      property: 'og:description',
      content: description
    },
    { hid: 'og-type', property: 'og:type', content: 'website' },
    ...(fullPath
      ? [
          {
            hid: 'og-url',
            property: 'og:url',
            content: fullPath
          }
        ]
      : []),
    // { hid: 'og-image', property: 'og:image', content: metaContent.image },
    // { hid: 'og-locale', property: 'og:locale', content: 'en_US' },
    {
      hid: 'og-site-name',
      property: 'og:site_name',
      content: ogSiteName
    },
    {
      hid: 'twitter-card',
      name: 'twitter:card',
      content: 'summary'
    },
    {
      hid: 'twitter-site',
      name: 'twitter:site',
      content: '@ForexLive'
    },
    {
      hid: 'twitter-title',
      name: 'twitter:title',
      content: title
    },
    {
      hid: 'twitter-description',
      name: 'twitter:description',
      content: description
    },
    ...generateThumbnailMetaSettings(ogImageUrl)
    // {
    //   hid: 'twitter-image',
    //   name: 'twitter:image',
    //   content: metaContent.image
    // },
    // {
    //   hid: 'twitter-image-alt',
    //   name: 'twitter:image:alt',
    //   content: metaContent.title
    // }
  ]
}

Vue.use(createMeta)
