<template>
  <ul class="header-navigation-submenu__wrapper">
    <li
      v-for="(item, index) in items"
      class="header-navigation-submenu__item"
      :class="getHeaderNavigationItemClass(index)"
      :key="`${item.Name}${index}`"
      @touchstart="navigation.onListItemTouchstart($event)"
      @click="navigation.onListItemClick($event, index)"
      @mouseenter="navigation.onListItemMouseEnter($event, index)"
      @mouseleave="navigation.onListItemMouseLeave($event, index)"
    >
      <div class="header-navigation__list-item-link-wrapper">
        <a-link
          :to="item.Link"
          :trailing-slash="$helper.isLinkWithTrailingSlash(item.Link)"
          class="header-navigation__list-link"
          :class="$options.consts.HEADER_SUBMENU_ITEM_CLASS"
          tabindex="0"
          :[$options.consts.DATA_NESTING_LEVEL_ATTRIBUTE]="nestingLevel"
          @click="navigation.onLinkClick($event, index)"
          @focus="navigation.onLinkFocus($event, index)"
          @blur="navigation.onLinkBlur($event)"
          >{{ item.Name }}
        </a-link>

        <a-icon
          v-if="isItemWithChildren(item)"
          :icon="ICON.ARROW_RIGHT_WHITE"
          :width="8"
          :height="8"
          class="header-navigation__arrow"
        />
      </div>

      <a-header-navigation-submenu
        v-if="isItemWithChildren(item)"
        :items="item.ChildItems"
        :nesting-level="nestingLevel + 1"
        class="header-navigation-submenu__nested"
        @item-click="navigation.onSubMenuClick($event)"
        @blur="navigation.onSubMenuBlur($event, index)"
      />
    </li>
  </ul>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AIcon, { ICON } from 'shared/AIcon'
import { HEADER_SUBMENU_ITEM_CLASS } from '@/components/AHeader/AHeaderDesktop/enums'
import { Navigation } from '@/utils/helpers/navigation'
import { getParentWith } from '@fmpedia/helpers'
import { HTML_TAG } from '@fmpedia/enums'

const DATA_NESTING_LEVEL_ATTRIBUTE = 'data-nesting-level'

export default {
  name: 'AHeaderNavigationSubmenu',
  components: { AIcon },
  props: {
    items: propValidator([PROP_TYPES.ARRAY]),
    nestingLevel: propValidator([PROP_TYPES.NUMBER], false, 0)
  },
  consts: {
    HEADER_SUBMENU_ITEM_CLASS,
    DATA_NESTING_LEVEL_ATTRIBUTE
  },
  data() {
    return {
      ICON,
      activeElementIndex: null,
      navigation: new Navigation({
        onMouseHover: this.openSubmenu.bind(this),
        onMouseAway: this.closeSubmenu.bind(this),
        onTextClick: this.onItemClick.bind(this),
        onTouchArrowClick: this.openSubmenu.bind(this),
        onTabFocus: this.openSubmenu.bind(this),
        onBlur: this.closeSubmenu.bind(this),
        onSubMenuClick: this.onItemClick.bind(this),
        onSubMenuBlur: this.closeSubmenu.bind(this)
      })
    }
  },
  methods: {
    isItemWithChildren(item) {
      return item.ChildItems && item.ChildItems.length
    },
    isNestedItem(el) {
      if (!el) return false

      return (
        this.isChildMenuItem(el) &&
        Number(el.getAttribute(DATA_NESTING_LEVEL_ATTRIBUTE)) >
          this.nestingLevel
      )
    },
    isChildMenuItem(el) {
      if (!el || !el.classList) return false

      return el.classList.contains(HEADER_SUBMENU_ITEM_CLASS)
    },
    getHeaderNavigationItemClass(index) {
      return {
        opened: this.activeElementIndex === index
      }
    },
    openSubmenu(index) {
      this.activeElementIndex = index
    },
    closeSubmenu(event, index) {
      if (this.isNestedItem(event.relatedTarget)) return

      this.activeElementIndex = null

      /**
       * During a blur event, the `target` refers to the element that lost focus,
       * and `relatedTarget` refers to the element that received focus.
       *
       * This logic checks whether the newly focused element shares a common parent
       * with the current dropdown. If they do, the dropdown should remain open, as
       * the parent item is still selected and the dropdown is still in use.
       */
      if (this.checkIfFocusMovedToTheParentItem(event.relatedTarget, index)) {
        return
      }

      this.$emit('blur', event)
    },
    checkIfFocusMovedToTheParentItem(focusedEl, index) {
      if (!focusedEl) return false

      const vueParentList = this.$parent.$el
      const vueParentListItem = vueParentList.querySelectorAll(HTML_TAG.LI)[
        index
      ]

      return !!getParentWith(focusedEl, { refEl: vueParentListItem })
    },
    onItemClick() {
      this.$emit('item-click')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-navigation-submenu__wrapper {
  list-style: none;
  position: absolute;
  display: none;
  top: 35px;
  right: 0;
  background: $c--black;
  text-align: left;
  width: 192px;
  max-width: 192px;

  .header-navigation-submenu__item {
    white-space: normal;
    line-height: 22px;

    &.opened {
      & > .header-navigation-submenu__nested {
        display: block;
      }
    }

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 5px;
    }
  }

  .header-navigation__list-item-link-wrapper {
    width: 100%;
    position: relative;

    &:hover .header-navigation__arrow {
      /deep/ .svg-icon {
        fill: $c--main;
      }
    }

    &:hover .header-navigation__list-link {
      color: $c--main;
    }

    .header-navigation__list-link {
      display: block;
      padding: 8px 36px 13px 20px;
      width: 100%;
    }
  }

  .header-navigation-submenu__nested {
    top: 0;
    left: 100%;
    right: auto;
    min-height: 100%;
  }

  .header-navigation__arrow {
    padding: 13px;
    position: absolute;
    right: 8px;
    top: calc(50% - 7px);
    transform: translateY(-50%);
    z-index: 1;
  }
}
</style>
