import { compareRoutes } from '@fmpedia/helpers'

export default function({ store: { dispatch }, redirect, from, route }) {
  if (process.server) return redirect()

  const { isOnlyHashChanged } = compareRoutes(from, route)

  if (isOnlyHashChanged) return

  dispatch('requestHeaderMenuItems')
  dispatch('requestFooterMenuItems')
  dispatch('requestStayConnected')
}
