import { isNil, pathOr } from 'ramda'
import axios from 'axios'
import https from 'https'
import { isHrefValid } from '@fmpedia/validators'
import { removeStartingSlash } from '@fmpedia/helpers'

export { isHrefValid }

export function getUrlHost(url) {
  if (!url) return null

  try {
    return new URL(url).host
  } catch (err) {
    return null
  }
}

export function checkIfLinkHasProtocol(url) {
  if (!url) return false

  return /https?:\/\//.test(url)
}

export function generateFmUrl(link) {
  if (!link) return process.env.FM_DOMAIN_URL

  const linkWithoutStartSlash = removeStartingSlash(link)
  return checkIfLinkHasProtocol(linkWithoutStartSlash)
    ? link
    : `${process.env.FM_DOMAIN_URL}/${linkWithoutStartSlash}`
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const AXIOS_OPTIONS = {
  responseType: 'document',
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
}
const AXIOS_HEADER = { 'Accept-Language': 'en-US' }

export function axiosGet(url, params) {
  const isServer = isNil(process.server) || process.server

  const apiUrl = isServer
    ? process.env.API_URL_INTERNAL || process.env.API_URL
    : process.env.API_URL

  const fullApiUrl = `${apiUrl}/api/${url}`

  return axios.get(fullApiUrl, { ...AXIOS_OPTIONS, params }, AXIOS_HEADER)
}

export function getGaCookie(cookies = {}) {
  return cookies._ga && cookies._ga !== 'GA1.2'
    ? cookies._ga
    : generateGaCookie()
}

// Function to extract client_id from _ga cookie
export function extractClientIdFromGaCookie(gaCookie) {
  if (!gaCookie) return null
  const parts = gaCookie.split('.')

  // The _ga cookie should have the format "GA1.2.XXXXXXXXXX.YYYYYYYYYY"
  if (parts.length >= 4) {
    return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`
  }

  return null
}

export function generateGaCookie() {
  return `GA1.2.${generateClientId()}`
}

function generateClientId() {
  const MIN_RANDOM_INT = 100000000
  const MAX_RANDOM_INT = 999999999

  const randomInt = randomIntFromInterval(MIN_RANDOM_INT, MAX_RANDOM_INT)
  const dateInSeconds = Math.floor(+new Date() / 1000)

  return `${randomInt}.${dateInSeconds}`
}

export function parseClientIdFromGaCookie(gaCookie) {
  const COMMON_GA_COOKIE_PARTS_NUMBER = 4

  const arr = gaCookie.split('.')

  if (arr.length === COMMON_GA_COOKIE_PARTS_NUMBER) {
    return arr.slice(2).join('.')
  }

  return gaCookie
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function generateDeviceId() {
  return `${guid()}-${+new Date()}`
}

function testHrefValidator() {
  const hrefValidationTests = [
    { href: 'https://google.com', isValid: true },
    { href: 'http://google.com', isValid: true },
    { href: 'ftp://google.com', isValid: true },
    { href: '//google.com', isValid: true },
    { href: '/all-news', isValid: true },
    { href: '#', isValid: true },
    { href: '#anchor', isValid: true },
    { href: 'mailto:some@email.com', isValid: true },
    { href: 'mailto:some@gibberish', isValid: false },
    { href: 'mailto:', isValid: false },
    { href: 'discord://some-data', isValid: true },
    { href: 'all-news', isValid: false },
    { href: 'google.com', isValid: false },
    { href: 'Minister: how are you', isValid: false },
    { href: '', isValid: false }
  ]
  const results = hrefValidationTests.reduce((acc, { href, isValid }) => {
    const isValidJsLib = isHrefValid(href)
    return {
      ...acc,
      [href]: {
        isValidExpected: isValid,
        isValidActual: isValidJsLib,
        Passed: isValidJsLib === isValid
      }
    }
  }, {})
  if (Object.values(results).some(({ Passed }) => Passed === false)) {
    console.log('== server/helper.js - isHrefValid() helper test(s) failed ==')
    console.table(results)
  }
}

if (process.env.NODE_ENV === 'development') {
  testHrefValidator()
}

export function removePaginationPartFromPath(path) {
  return path.replace(/\/page\/\d+\/*$/i, '')
}

export function getBackendErrorCode(err) {
  return (
    pathOr(null, ['response', 'data', 'ErrorCode'], err) ||
    pathOr(null, ['response', 'data', 'Code'], err)
  )
}

export function getBackendErrorEvidence(err) {
  return pathOr(null, ['response', 'data', 'Evidence'], err)
}

export function isString(val) {
  return typeof val === 'string' || val instanceof String
}

export function queryStringToObject(str) {
  if (!isString(str) || str === '') return {}

  return JSON.parse(
    '{"' +
      decodeURI(str)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}

export function replaceDoubleQuotesBySingle(text) {
  if (!text || typeof text !== 'string') return text

  return text.replace(/"/g, "'")
}
