const EVENT = {
  LI_TOUCHSTART: 'li_touchstart',
  LI_MOUSEENTER: 'li_mouseenter',
  LI_MOUSELEAVE: 'li_mouseleave',
  LI_CLICK: 'li_click',
  LINK_FOCUS: 'link_focus',
  LINK_CLICK: 'link_click'
}

export class Navigation {
  constructor({
    onMouseHover,
    onMouseAway,
    onTextClick,
    onTouchArrowClick,
    onTabFocus,
    onBlur,
    onSubMenuClick,
    onSubMenuBlur
  }) {
    this._eventQueue = []

    this._onMouseHover = onMouseHover || this.emptyFn
    this._onMouseAway = onMouseAway || this.emptyFn
    this._onTextClick = onTextClick || this.emptyFn
    this._onTouchArrowClick = onTouchArrowClick || this.emptyFn
    this._onTabFocus = onTabFocus || this.emptyFn
    this._onBlur = onBlur || this.emptyFn
    this._onSubMenuClick = onSubMenuClick || this.emptyFn
    this._onSubMenuBlur = onSubMenuBlur || this.emptyFn

    this._debug = false
  }
  debugLog(...args) {
    if (!this._debug) return

    const [first, ...others] = args
    const firstArg = `navigation > ${first}`

    console.log(firstArg, ...others)
  }
  get eventQueue() {
    return this._eventQueue
  }
  set eventQueue(eventQueue) {
    this._eventQueue = eventQueue
  }
  get lastEvent() {
    if (!this.eventQueue.length) return

    return this.eventQueue[this._eventQueue.length - 1]
  }
  addEventToQueue(event) {
    this.debugLog('== addEventToQueue ==', event)
    this.debugLog(
      'before: ',
      this.eventQueue.map(e => e)
    )
    this._eventQueue.push(event)
    this.debugLog(
      'after: ',
      this.eventQueue.map(e => e)
    )
  }
  clearEventQueue() {
    this.debugLog('== clearEventQueue ==')
    this._eventQueue = []
  }
  emptyFn() {}
  onListItemTouchstart(event) {
    this.debugLog('== onListItemTouchstart ==')
    this.addEventToQueue(EVENT.LI_TOUCHSTART)
  }
  onListItemClick(event, index) {
    this.debugLog('== onListItemClick ==')
    /**
     * Touch to the arrow
     */
    if (this.eventQueue.includes(EVENT.LI_TOUCHSTART)) {
      this.debugLog(`call _onTouchArrowClick(${index})`)
      this.debugLog(
        'queue: ',
        this.eventQueue.map(e => e)
      )
      this._onTouchArrowClick(index)
    }
    this.clearEventQueue()
  }
  onListItemMouseEnter(event, index) {
    this.debugLog('== onListItemMouseEnter ==')
    /**
     * On mouse hover
     */
    if (!this.eventQueue.length) {
      this.addEventToQueue(EVENT.LI_MOUSEENTER)
      this.debugLog(`call _onMouseHover(${index})`)
      this.debugLog(
        'queue: ',
        this.eventQueue.map(e => e)
      )
      this._onMouseHover(index)
      this.clearEventQueue()
      return
    }

    if (this.lastEvent === EVENT.LI_MOUSELEAVE) {
      this.clearEventQueue()
    }
    this.addEventToQueue(EVENT.LI_MOUSEENTER)
  }
  onListItemMouseLeave(event, index) {
    this.debugLog('== onListItemMouseLeave ==')
    this.eventQueue = this.eventQueue.filter(
      event => event !== EVENT.LI_MOUSEENTER
    )

    /**
     * On touch events we ignore the mouseleave event.
     */
    if (!this.eventQueue.some(event => event === EVENT.LI_TOUCHSTART)) {
      this.debugLog(`call _onMouseAway($event, ${index})`)
      this.debugLog(
        'queue: ',
        this.eventQueue.map(e => e)
      )
      this._onMouseAway(event, index)
    }
  }
  async onLinkFocus(event, index) {
    this.debugLog('== onLinkFocus ==')
    this.addEventToQueue(EVENT.LINK_FOCUS)

    setTimeout(() => {
      /**
       * This issue occurs specifically with touch events when the user
       * touches an element outside the header items after initially focusing
       * on a header item. The exact cause of this behavior is unknown.
       */
      if (event.target !== document.activeElement) {
        this.debugLog('focus: on touch blur.')
        this.clearEventQueue()
        return
      }

      /**
       * If the last event is a focus, it means that it was fired by the
       * keyboard navigation.
       */
      if (this.eventQueue.length && this.lastEvent === EVENT.LINK_FOCUS) {
        this.debugLog(`call _onTabFocus(${index})`)
        this.debugLog(
          'queue: ',
          this.eventQueue.map(e => e)
        )
        this._onTabFocus(index)
        this.clearEventQueue()
      }
    }, 0)
  }
  onLinkBlur(event, index) {
    this.debugLog('== onLinkBlur ==')
    /**
     * Typically, a dropdown is opened by touching an arrow. However,
     * for top-level menu items without links (e.g., the "More" menu item),
     * the dropdown can also be opened by touching the text.
     *
     * When navigating within the dropdown, touching an arrow to open submenu items
     * triggers a blur event on the text element. In this case, the top-level dropdown
     * should remain open since the user is still interacting with it.
     */
    if (event.target.parentNode.contains(event.relatedTarget)) {
      return
    }

    this.debugLog(`call _onBlur($event, ${index})`)
    this.debugLog(
      'queue: ',
      this.eventQueue.map(e => e)
    )
    this._onBlur(event, index)
  }
  onLinkClick(event, index) {
    this.debugLog('== onLinkClick ==')
    this.debugLog(`call _onTextClick(${index})`)
    this.debugLog(
      'queue: ',
      this.eventQueue.map(e => e)
    )
    this._onTextClick(index)
    this.clearEventQueue()
    event.stopPropagation()
  }
  onSubMenuClick(event, index) {
    this.debugLog('== onSubMenuClick ==')
    this.debugLog(`call _onSubMenuClick($event, ${index})`)
    this.debugLog(
      'queue: ',
      this.eventQueue.map(e => e)
    )
    this._onSubMenuClick(event, index)
    this.clearEventQueue()
  }
  onSubMenuBlur(event, index) {
    this.debugLog('== onSubMenuBlur ==')
    this.debugLog(`call _onSubMenuBlur($event, ${index})`)
    this.debugLog(
      'queue: ',
      this.eventQueue.map(e => e)
    )
    this._onSubMenuBlur(event, index)
  }
}
