import { pipe } from 'ramda'

import { MIDDLEWARE } from 'enums/middleware'
import { REDIRECT_TYPE_CODES } from '@fmpedia/enums'
import { SOURCE } from 'enums/source'
import { getPageSourceByRouteName, prettifyPath } from '@/plugins/helper'
import { isMiddlewareSkipped } from '@/utils/helpers'
import { removeAmpSuffix } from '@fmpedia/helpers'

const ACTION_BY_PAGE_SOURCE = {
  [SOURCE.FL]: 'seo/requestFlRedirect',
  [SOURCE.FL_DIR]: 'seo/requestFlDirRedirect'
}

export async function getSeoRedirectSettings({ route, ctx }) {
  console.time(`seo-redirects - ${route.path}`)

  if (isMiddlewareSkipped(MIDDLEWARE.SEO_REDIRECT, route)) {
    return null
  }

  try {
    if (route.query.previewId) return null

    const pageSource = getPageSourceByRouteName(route.name)

    const from = pipe(prettifyPath, removeAmpSuffix)(route.path) || '/'

    const { Type, Value } = await ctx.store.dispatch(
      ACTION_BY_PAGE_SOURCE[pageSource],
      {
        from
      }
    )

    if (Type && Value) {
      return {
        type: REDIRECT_TYPE_CODES[Type],
        to: Value
      }
    }
  } catch (err) {
    console.error('err in seo-redirects: ', err)
    return null
  }
  console.timeEnd(`seo-redirects - ${route.path}`)
}
